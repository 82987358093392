import React, {useState, useEffect} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import 'react-select/dist/react-select.min.css';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Widget from "../../components/Widget/Widget";
import gatewayService from "../../services/gatewayService";
import garageService from "../../services/garageService";
import getGaragesQuery from '../../queries/getGarages';
import setNewNfcGateway from "../../mutations/setNewNfcGateway";
import datetimeFormatter from "../../utils/datetimeFormatter";
import Logs from "../../components/Logs";
import {history} from "../../App";
import getGatewayByGwid from "../../queries/getGatewayByGwid";
import assignNode from "../../mutations/assignNode";
import getGateways_2 from "../../queries/getGateways_2";
import assignMaster from "../../mutations/assignMaster";
import unassignMaster from "../../mutations/unassignMaster";
import unassignNode from "../../mutations/unassignNode";
import dictionary from "../../utils/dictionary";
import Slaves from "../../components/Slaves";
import HealthStatus from "../../components/HealthStatus";
import triggerHealthCheck from "../../mutations/triggerHealthCheck";
import updateNewGateway from "../../mutations/updateNewGateway";
import rebootModule from "../../mutations/rebootModule";
import {Link} from "react-router-dom";
import Select from "react-select";
import getTenantsQuery from "../../queries/getTenantsQuery";


const Gateway_2 = (props) => {

    const [loading, setLoading] = useState(true);
    const [nfcLoading, setNfcLoading] = useState(false);
    const [garageDataLoading, setGarageDataLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [showTypeWarning, setShowTypeWarning] = useState(false);
    const [isUnassignNode, setUnassignNode] = useState(false);
    const [isUnassignMaster, setUnassignMaster] = useState(false);
    const [isAssignNode, setAssignNode] = useState(false);
    const [isAssignMaster, setAssignMaster] = useState(false);
    const [assignedValue, setAssignedValue] = useState('');
    const [gateway, setGateway] = useState(null);
    const [tenants, setTenants] = useState([])
    const [productTypes, setProductType] = useState([{value: "B2C", label: "B2C"}, {value: "B2B", label: "B2B"}, {value: "B2B_B2C", label: "Hybrid"},])
    let typingTimer;
    const typingInterval = 600;

    useEffect(() => {
        fetchGateway();
        getTenants();
    }, []);

    useEffect(() => {
        if (gateway) {
            document.title = "Gateway | " + (gateway.name || dictionary.NOT_AVAILABLE);
        }
    }, [gateway]);

    useEffect(() => {
        fetchGateway();
    }, [props]);

    function fetchGateway() {
        const {match: {params}} = props;
        gatewayService.getService(getGatewayByGwid(params))
            .then((gateway) => {
                setGateway(gateway.data.getByGatewayGwid.garageGateway);
                setLoading(false);
                document.title = "Gateway | " + (gateway.name || dictionary.NOT_AVAILABLE);
            })
            .catch((err) => {
                if (err.networkError && err.networkError.statusCode === 404) {
                    history.push('/404')
                }
            });
    }

    function handleTenant(tenant) {
        setGateway({
            ...gateway,
            userpool_id: !tenant ? null : tenant.value,
        });
    }

    function handleProductType(product_type) {
        setGateway({
            ...gateway,
            product_type: !product_type ? null : product_type.value
        })
    }

    function getTenants() {
        gatewayService.getService(getTenantsQuery())
            .then((res) => {
                let tenants = res.data.getTenants.tenants;
                tenants = tenants.map((item) => {
                    return {
                        value: item.Id,
                        label: item.Name
                    }
                })
                setTenants(tenants)
            })
            .catch((err) => {
                console.log(err)
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            })
    }

    function handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let _gateway = {...gateway};

        if (name === "type" && !value.length) {
            value = null;
        }

        if (value === "NFC_WRITE") {
            return toggleTypeWarning();
        }

        if ((name === "relay0" || name === "relay1" || name === "relay2") && gateway.garage_id) {
            switch (name) {
                case "relay0":
                    _gateway.active_relays[0] = value;
                    break;
                case "relay1":
                    _gateway.active_relays[1] = value;
                    break;
                case "relay2":
                    _gateway.active_relays[2] = value;
                    break;
                default:
                    break;
            }
        }

        _gateway[name] = value;

        setGateway(_gateway);
    }

    function unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    function success() {
        return toast.success('Erfolgreich!');
    }

    function handleAssignErrors(err) {
        if (err && err.networkError) {
            const errors = err.networkError.result.errors;
            unsuccessful(errors.map((error) => error.message).join(" "));
        }
    }

    function onGarageChanged(assignedGarage) {
        setAssignedValue(assignedGarage);
    }

    function loadNodes(value) {
        return new Promise(resolve => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                let params = {
                    first: 100,
                    offset: 0,
                    name: `"${value}"`
                };

                garageService.getService(getGateways_2(params))
                    .then((gateways) => {
                        resolve({
                            options: gateways.data.getNewGateways.garageGateways.map((gateway) => {
                                let label =  gateway.name || gateway.gwid;

                                if (gateway.garage_gateway) {
                                    if(gateway.name){
                                        label = gateway.name + ' | ' + gateway.garage_gateway.name
                                    } else {
                                        label = gateway.gwid + ' | ' + gateway.garage_gateway.name;
                                    }
                                }
                                if(gateway.is_master){
                                    return {gwid: gateway.gwid, value: gateway.gwid, label: label}
                                }
                            }).filter((value)=>{return value!=null})
                        })
                    });
            })
        })
    }

    function loadGarages(value) {
        return new Promise(resolve => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {

                let params = {
                    offset: 0,
                    limit: 10,
                    searchTerm: value
                };

                garageService.getService(getGaragesQuery(params))
                    .then((garages) => {

                        resolve({
                            options: garages.data.getGarages.garages.map((garage) => {
                                return {value: garage.id, label: garage.name}
                            })
                        })
                    })
            }, typingInterval)

        })
    }

    function toggleUnassignNode() {
        setUnassignNode(!isUnassignNode);
    }

    function toggleUnassignMaster() {
        setUnassignMaster(!isUnassignMaster);
    }

    function toggleAssignNode() {
        setAssignNode(!isAssignNode);
    }

    function toggleTypeWarning() {
        setShowTypeWarning(prev => !prev)
    }

    function toggleAssignMaster() {
        setAssignMaster(!isAssignMaster);
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function startUpdateGateway() {
        garageService.createService(updateNewGateway(gateway))
            .then((response) => {
                console.log(response.data.updateNewGateway)
                setGateway(response.data.updateNewGateway.garageGateway);
                success();
                toggleDisabled();
                document.title = "Gateway | " + gateway.name || dictionary.NOT_AVAILABLE;
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function handleRebootModule(moduleType) {
        garageService.createService(rebootModule({gwid: gateway.gwid, [moduleType]: true}))
            .then(() => {
                success();
            })
            .catch((err)=>{
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function startAssignMaster() {
        const data = {
            gwid: gateway.gwid,
            garageId: assignedValue.value
        };

        garageService.createService(assignMaster(data))
            .then((response) => {
                fetchGateway();
                setAssignMaster(false);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            });
    }

    function startUnassignMaster(){
        const data = {
            gwid: gateway.gwid
        };

        garageService.createService(unassignMaster(data))
        .then((response)=>{
            fetchGateway();
                setUnassignMaster(false);
                success();
        })
        .catch((err)=>{
            handleAssignErrors(err);
        })
    }

    function startAssignNode() {
        const data = {
            gwid: gateway.gwid,
            masterGwid: assignedValue.gwid
        };

        garageService.createService(assignNode(data))
            .then((response) => {
                fetchGateway();
                setAssignNode(!isAssignNode);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            })
    }

    function startUnassignNode() {
        const data = {
            gwid: gateway.gwid
        };

        garageService.createService(unassignNode(data))
            .then((response) => {
                fetchGateway();
                setUnassignNode(!isUnassignNode);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            });
    }

    function setAsNFCGateway() {
        setNfcLoading(true);
        garageService.createService(setNewNfcGateway(gateway.id))
            .then((response) => {
                setGateway({
                    ...gateway,
                    type: response.data.setNewNfcGateway.gateway.type
                });
                setNfcLoading(false);
                success();
            })
            .catch((err) => {
                setNfcLoading(false);
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function onTriggerHealthCheck() {
        garageService.createService(triggerHealthCheck(gateway.gwid))
            .then((response) => {
                success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function renderNodeGateway() {
        if (!gateway.garage_id) {
            return <Widget
                icon="icon-plus"
                color="success"
                header="Knoten zuweisen"
                invert
                style={{cursor: "pointer"}}
                onClick={toggleAssignNode}
            />
        }
        return (
            <Widget
                icon="icon-plus"
                color="success"
                header="Knoten nicht zugewiesen"
                invert
                style={{cursor: "pointer"}}
                onClick={toggleUnassignNode}
            />);
    }

    function renderInitialHeader() {
        return (
            <Row className="justify-content-between">
                <Col>
                    <Widget icon="icon-organization" color="info"
                            header={gateway.name || dictionary.NOT_AVAILABLE + " | " + gateway.gwid}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    {!gateway.is_master ? renderNodeGateway() :
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Master zuweisen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={toggleAssignMaster}
                        />
                    }
                </Col>
                {gateway.is_master && <Col xs="12" sm="12" md="2" lg="2">
                    {
                        nfcLoading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Widget
                                icon="icon-plus"
                                color="success"
                                header="NFC Gateway"
                                invert
                                style={{cursor: "pointer"}}
                                onClick={setAsNFCGateway}
                            />
                    }
                </Col>}
            </Row>
        )
    }

    function renderAssignedHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="10" lg="10">
                    <Widget icon="icon-organization" color="info"
                            header={gateway.name || dictionary.NOT_AVAILABLE + " | " + gateway.gwid}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-minus"
                        color="warning"
                        header={gateway.is_master ? 'Master Aufheben' : 'Gateway Aufheben'}
                        invert
                        style={{cursor: "pointer"}}
                        onClick={gateway.is_master ? toggleUnassignMaster : toggleUnassignNode}
                    />
                </Col>
            </Row>
        )
    }

    function renderNFCHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                    <Widget icon="icon-organization" color="info"
                            header={gateway.name || dictionary.NOT_AVAILABLE + " | " + gateway.gwid}/>
                </Col>
            </Row>
        )
    }

    function renderHeader() {
        if (gateway.type === 'NFC_WRITE') {
            return renderNFCHeader()
        } else if (gateway.garage_id) {
            return renderAssignedHeader()
        } else if (gateway.type !== 'NFC_WRITE' && !gateway.gatewayGarage) {
            return renderInitialHeader()
        }
    }

    function renderState(state) {
        return <div>
            <i className={`fa fa-circle ${state === dictionary.ONLINE ? 'text-success' : 'text-danger'} Blink`}/>
            <span>{state}</span>
        </div>;
    }

    function renderModule(moduleName, onReboot, state, moduleType) {
        return (
            <Card>
                <CardHeader>
                    <strong>{moduleName}</strong>
                </CardHeader>
                <CardBody>
                    {renderState(state)}
                    <Button type="submit" size="sm" color="success" className={"mr-1"}
                            onClick={() => onReboot(moduleType)}>
                        <i className="fa fa-refresh"/>
                        Reboot
                    </Button>
                </CardBody>
            </Card>
        )
    }

    function renderActiveRelayState(activeRelays) {
        return activeRelays.map((relay, index) => {
            return (
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Input type="select" name={"relay" + index} id={`relay`}
                               value={gateway.active_relays[index]}
                               disabled={!gateway.garage_id || disabled}
                               onChange={handleChange}>
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </Input>
                    </Col>
                </FormGroup>
            )
        }).reverse();
    }


    const containerStyle = {
        zIndex: 1999
    };

    if (loading) {
        return (
            <div>
                <div className="sk-rotating-plane"/>
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
            </div>
        );
    }

    return (
        <div className="animated fadeIn">
            <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
            {renderHeader()}
            <Row>
                <Col xs="12" sm="12" md="12">
                    <Row>
                        <Col md={3}>
                            {renderModule('BLE Modul', handleRebootModule, gateway.health_status && gateway.health_status.ble_state ? dictionary.ONLINE : dictionary.OFFLINE, 'ble')}
                        </Col>
                        <Col md={3}>
                            {renderModule('NFC Modul', handleRebootModule, gateway.health_status && gateway.health_status.nfc_state ? dictionary.ONLINE : dictionary.OFFLINE, 'nfc')}
                        </Col>
                        <Col md={3}>
                            {renderModule('RF Modul', handleRebootModule, gateway.health_status && gateway.health_status.rf_state ? dictionary.ONLINE : dictionary.OFFLINE, 'rf_module')}
                        </Col>
                        <Col md={3}>
                            {renderModule('Main Controller', handleRebootModule, gateway.health_status && gateway.health_status.main_controller_state ? dictionary.ONLINE : dictionary.OFFLINE, 'main_controller')}
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" sm="12" md="12">
                    <Card>
                        <CardHeader>
                            Gateway Infos
                            <div className="card-header-actions">
                                {
                                    !disabled
                                        ?
                                        <div>
                                            <Button type="submit" size="sm" color="success" className={"mr-1"}
                                                    onClick={startUpdateGateway}>
                                                <i className="fa fa-dot-circle-o"></i> Speichern
                                            </Button>
                                            <Button type="reset" size="sm" color="danger" onClick={toggleDisabled}>
                                                <i className="fa fa-ban"></i> Abbrechen</Button></div> :
                                        <a data-testid="gateway-edit-button"
                                           className="card-header-action btn btn-setting" onClick={toggleDisabled}><i
                                            className="icon-pencil"></i></a>
                                }
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="12" sm="12" md="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Name</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input data-testid="gateway-name-input" type="text" name="name" id="name"
                                                   value={!gateway.name ? "" : gateway.name}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Is Master</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="is_master" id="name"
                                                   value={gateway.is_master}
                                                   onChange={handleChange} disabled={true}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Microtronics Site ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="microtronics_id" id="microtronics_id"
                                                   value={gateway.microtronics_site_id || ""}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Device ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="device_id" id="device_id"
                                                   value={gateway.device_id || ""} onChange={handleChange}
                                                   disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Typ</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="select" name="type" id="type"
                                                   value={!gateway.type ? dictionary.NOT_AVAILABLE : gateway.type}
                                                   onChange={handleChange}
                                                   disabled={gateway.type === 'NFC_WRITE' || disabled}>
                                                <option value="">-</option>
                                                <option value="CHECK_IN">Check In</option>
                                                <option value="CHECK_OUT">Check Out</option>
                                                <option value="CHECK_IN_OUT">Check In/Out</option>
                                                <option value="MIDGATEWAY">Zwischentür</option>
                                                <option value="MIDGATEWAY_ONLINE">Zwischentür (Online)</option>
                                                <option value="MIDGATEWAY_OFFLINE">Zwischentür (Offline)</option>
                                                <option value="NFC_WRITE">NFC Gateway</option>
                                                <option value="EXIT_BARRIER">Ausfahrtsschranken</option>
                                                <option value="EXIT_BARRIER_ONLINE">Ausfahrtsschranken (Online)</option>
                                                <option value="EXIT_BARRIER_OFFLINE">Ausfahrtsschranken (Offline)
                                                </option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                    <Col md="3">
                                            <Label htmlFor="selectSm">State</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {renderState(gateway.state)}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Health Status</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {
                                                !gateway.health_status ? <p>k.A</p> :
                                                <>
                                                    <HealthStatus healthStatus={gateway.health_status}/>
                                                    <Button type="submit" size="sm" color="success" className={"mr-1"}
                                                            onClick={onTriggerHealthCheck}>
                                                        <i className="fa fa-refresh"/>
                                                        Trigger health check
                                                    </Button>
                                                </>
                                            }
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Garage ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {gateway.garage_gateway && <Link to={`/garages/${gateway.garage_gateway.id}`}>{`${gateway.garage_gateway.name} | ${gateway.garage_gateway.id}`}</Link>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Tenant</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Select
                                                value={!gateway ? null : gateway.userpool_id}
                                                options={tenants}
                                                onChange={handleTenant}
                                                disabled={disabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Signalstärke</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="signal_strength" id="signal_strength"
                                                   value={gateway.connection_device ? gateway.connection_device.signal_strength : ''}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Location</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="location" id="location"
                                                   value={!gateway.location ? "" : gateway.location}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Active Relays</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {gateway.garage_id ? renderActiveRelayState(gateway.active_relays) : dictionary.NOT_AVAILABLE}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="gwid">GWID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input data-testid="gateway-gwid-input" type="text" name="gwid" id="gwid"
                                                   value={gateway.gwid}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Erstellt am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="created_at" id="created_at"
                                                   value={datetimeFormatter.forDisplay(gateway.createdAt)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Aktualisiert am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="updated_at" id="updated_at"
                                                   value={datetimeFormatter.forDisplay(gateway.updatedAt)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Gelöscht am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="deleted_at" id="deleted_at"
                                                   value={!gateway.deleted_at ? "-" : datetimeFormatter.forDisplay(gateway.deleted_at)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Product type</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Select
                                                value={!gateway.product_type ? null : gateway.product_type}
                                                options={productTypes}
                                                onChange={handleProductType}
                                                disabled={disabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Firmware</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="firmware" id="firmware"
                                                   value={!gateway.firmware ? "" : gateway.firmware}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <hr/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label>Notiz</Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="note" id="note"
                                                   value={!gateway.note ? "" : gateway.note}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {gateway.is_master &&
            <Row>
                <Col xs="12" sm="12" lg="12">
                    <Slaves slaves={gateway.slaves}/>
                </Col>
            </Row>
            }
            <Row>
                <Col xs="12" sm="12" lg="12">
                    {
                        garageDataLoading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Logs
                                object_id={gateway.gwid}
                                object_content_type="GATEWAY"
                            />
                    }
                </Col>
            </Row>

            {/* Modals */}

            {/* Unassign node */}
            <Modal isOpen={isUnassignNode} toggle={toggleUnassignNode}
                   className={'modal-warning ' + props.className}>
                <ModalHeader toggle={toggleUnassignNode}>Heben Sie die Zuweisung des Knotens auf</ModalHeader>
                <ModalFooter>
                    <Button color="warning" onClick={startUnassignNode}>Zuweisung des Knotens aufheben</Button>{' '}
                    <Button color="secondary" onClick={toggleUnassignNode}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* Assign to node */}
            <Modal isOpen={isAssignNode} toggle={toggleAssignNode}
                   className={'modal-success ' + props.className}>
                <ModalHeader toggle={toggleAssignNode}>Knoten zuweisen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        name="assignedGarage"
                        loadOptions={loadNodes}
                        value={assignedValue}
                        onChange={onGarageChanged}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={startAssignNode}>Knoten zuweisen</Button>{' '}
                    <Button color="secondary" onClick={toggleAssignNode}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* NFC_GATEWAY Typechange warning */}
                <Modal isOpen={showTypeWarning} toggle={toggleTypeWarning}
                       className={'modal-warning ' + props.className}>
                    <ModalHeader toggle={toggleTypeWarning}>Achtung</ModalHeader>
                    <ModalBody>
                        <Label htmlFor="newGatewayGwid">Bist du sicher, dass du den Gateway-Typ auf 'NFC Gateway' ändern möchtest?</Label>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={()=>{
                            let _gateway = {...gateway};

                            _gateway['type'] = 'NFC_WRITE';

                            setGateway(_gateway);
                            toggleTypeWarning();
                        }}>Yes</Button>
                        <Button color="secondary" onClick={toggleTypeWarning}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>

            {/*Assign to master*/}
            <Modal isOpen={isAssignMaster} toggle={toggleAssignMaster}
                   className={'modal-success ' + props.className}>
                <ModalHeader toggle={toggleAssignMaster}>Master zuweisen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        name="assignedGarage"
                        loadOptions={loadGarages}
                        value={assignedValue}
                        onChange={onGarageChanged}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={startAssignMaster}>Master zuweisen</Button>{' '}
                    <Button color="secondary" onClick={toggleAssignMaster}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* Unassign master */}
            <Modal isOpen={isUnassignMaster} toggle={toggleUnassignMaster}
                   className={'modal-warning ' + props.className}>
                <ModalHeader toggle={toggleUnassignMaster}>Heben Sie die Zuordnung des Masters auf</ModalHeader>
                <ModalFooter>
                    <Button color="warning" onClick={startUnassignMaster}>Zuordnung des Masters aufheben</Button>{' '}
                    <Button color="secondary" onClick={toggleUnassignMaster}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
};

export default Gateway_2;
