import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col, Input,
    InputGroup,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import {Link} from "react-router-dom";
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";

import Widget from "../../components/Widget/Widget";
import CustomSearchField from "../../components/CustomSearchField";
import CustomDropdownFilter from "../../components/CustomDropdownFilter";
import dictionary from "../../utils/dictionary";
import garageService from "../../services/garageService";
import getPayucaConnectGateways from "../../queries/getPayucaConnectGateways";
import DownloadPayucaConnectGateways from "./DownloadPayucaConnect/DownloadPayucaConnect";
import createPCGateway from "../../mutations/createPayucaConnectGateway";


const gatewayType = {
    'CHECK_IN': 'Check In',
    'CHECK_OUT': 'Check Out',
    'CHECK_IN_OUT': 'Check In/Out',
    'MIDGATEWAY': 'Zwischentür',
    'REPEATER': 'Signalverstärker',
    'EXIT_BARRIER': 'Ausfahrtsschranken',
};

const gatewayState = {
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
};


function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
}

class PayucaConnectGateways extends Component {

    static ToGateway(cell, row) {
        const id = row.id;
        return <Link data-testid='gwid-row' to={`/payuca-connect/${id}`}> {cell} </Link>;
    }

    static ToMasterGateway(cell, row) {
        const id = row.master_id;
        return <Link to={`/payuca-connect/${id}`}> {cell} </Link>;
    }

    static Assigned(garage, cell) {
        if (cell.payuca_connect_garage) {
            return <Link to={`/garages/${cell.payuca_connect_garage.id}`}>{cell.payuca_connect_garage.name}</Link>
        } else {
            return <div>-</div>
        }
    }

    static HealthStyle(cell) {
        const color = cell.connection ? 'text-success' : 'text-danger';
        return <div><i className={`fa fa-circle ${color} Blink`}/> {cell.connection ? 'Online' : 'Offline'}</div>
    }

    static IDTooltipFormatter(cell, row) {
        const id = row.id;

        return (
            <div>
                <Link to={`/gateways/${id}`} id={'Tooltip-' + id}>{cell}</Link>
                <UncontrolledTooltip placement="top" target={'Tooltip-' + id}>
                    {cell}
                </UncontrolledTooltip>
            </div>
        )
    }
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gateways: [],
            page: 1,
            totalSize: 0,

            nameFilter: "",
            gwidFilter: "",
            deviceIDFilter: "",
            stateFilter: "",
            typeFilter: "",
            assignedFilter: null,

            assign: false,
            nodeGwid: '',
            nodeDeviceId: '',
            nodeId: '',
            assignedNode: '',
            dropdownOpen: false,
            selectedOption: 'Node'
        };

        this.unsuccessful = this.unsuccessful.bind(this);
        this.success = this.success.bind(this);

        this.onPageChange = this.onPageChange.bind(this);
        this.fetchGateways = this.fetchGateways.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onGWIDFilter = this.onGWIDFilter.bind(this);
        this.onDeviceIDFilter = this.onDeviceIDFilter.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.getStateFilter = this.getStateFilter.bind(this);
        this.handleStateFilter = this.handleStateFilter.bind(this);
        // this.filterHealthStatus = this.filterHealthStatus.bind(this);

        this.getTypeFilter = this.getTypeFilter.bind(this);
        this.handleTypeFilter = this.handleTypeFilter.bind(this);

        this.getAssignedFilter = this.getAssignedFilter.bind(this);
        this.handleAssignedFilter = this.handleAssignedFilter.bind(this);

        this.toggleAssign = this.toggleAssign.bind(this);
        this.onNodeGwidChange = this.onNodeGwidChange.bind(this);
        this.onNodeIdChange = this.onNodeIdChange.bind(this);
        this.onNodeDeviceIdChange = this.onNodeDeviceIdChange.bind(this);

        this.loadNodes = this.loadNodes.bind(this);
        this.onNodeChanged = this.onNodeChanged.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.selectOption = this.selectOption.bind(this);


        this.typingTimer = null;
        this.typingInterval = 600;

    }

    unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    getQueryParams() {
        return {
            first: 10,
            offset: (this.state.page - 1) * 10,
            name: `"${this.state.nameFilter}"`,
            gwid: `"${this.state.gwidFilter}"`,
            device_id: `"${this.state.deviceIDFilter}"`,
            state: this.state.stateFilter,
            type: this.state.typeFilter,
            assigned: this.state.assignedFilter
        }
    }

    componentDidMount() {
        this.fetchGateways();
        document.title = "PAYUCA Cockpit | PayucaConnect"
    }


    fetchGateways() {
        garageService.getService(getPayucaConnectGateways(this.getQueryParams()))
            .then((gateways) => {
                this.setState({
                    gateways: gateways.data.getPCGateways.gateways,
                    loading: false,
                    totalSize: gateways.data.getPCGateways.count
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.nameFilter}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.nameFilter !== val) {
            this.setState({nameFilter: val});
            this.filterApplied();
        }
    }

    onGWIDFilter(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.gwidFilter !== val) {
            this.setState({gwidFilter: val});
            this.filterApplied();
        }
    }

    onDeviceIDFilter(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.deviceIDFilter !== val) {
            this.setState({deviceIDFilter: val});
            this.filterApplied();
        }
    }

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    dropdownClearState() {
        this.setState({
            nodeDeviceId: '',
            nodeGwid: ''
        });
    }

    selectOption(option) {
        this.dropdownClearState();
        this.setState({ selectedOption: option });
    }


    filterApplied() {
        this.setState({
            page: 1,
            loading: true,
            gateways: [],
        }, () => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
        })
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {
                    this.onClearButtonClicked(onClick)
                }}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.nameFilter !== "") {
            this.setState({
                page: 1,
                loading: true,
                gateways: [],
                nameFilter: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>)
        }
        return "No gateways found"
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            gateways: [],
            page
        }, this.fetchGateways)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10
        }
    }

    handleStateFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            stateFilter: val
        }, this.fetchGateways)
    }


    getStateFilter() {
        return (
            <CustomDropdownFilter
                testId='state-filter'
                options={[{
                    value: "ONLINE", label: "ONLINE"
                }, {
                    value: "ATTENTION", label: "ATTENTION"
                }, {
                    value: "OFFLINE", label: "OFFLINE"
                }]}
                filterHandler={this.handleStateFilter}/>
        )
    }

    getHealthFilter() {
        return (
            <CustomDropdownFilter
                testId='state-filter'
                options={[{
                    value: "OK", label: dictionary.OK
                }, {
                    value: "WARNING", label: dictionary.WARNING
                }, {
                    value: "DANGER", label: dictionary.DANGER
                }]}/>
        )
    }

    handleTypeFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            typeFilter: val
        }, this.fetchGateways)
    }

    getTypeFilter() {
        let options = [
            {value: "CHECK_IN", label: "Check In"},
            {value: "CHECK_OUT", label: "Check Out"},
            {value: "CHECK_IN_OUT", label: "Check In/Out"},
            {value: "MIDGATEWAY", label: "Zwischentür"},
            {value: "REPEATER", label: "Signalverstärker"},
            {value: "EXIT_BARRIER", label: "Ausfahrtsschranken"},
        ];
        return (
            <CustomDropdownFilter
                testId='type-filter'
                defaultValue={''}
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    getGWIDFilter = () => {
        return (
            <CustomSearchField
                placeholder="Search by GWID"
                search={this.onGWIDFilter}/>
        )
    };

    getDeviceIDFilter = () => {
        return (
            <CustomSearchField
                placeholder="Search by ID"
                search={this.onDeviceIDFilter}/>
        )
    };

    handleAssignedFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            assignedFilter: val === "true" ? "ASSIGNED" : (val === "false" ? "UNASSIGNED" : null)
        }, this.fetchGateways)
    }

    getAssignedFilter() {
        let options = [
            {value: true, label: "Ja"},
            {value: false, label: "Nein"}
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleAssignedFilter}/>
        )
    }

    toggleAssign() {
        this.setState({
            assign: !this.state.assign,
            assignedNode: '',
            nodeGwid: '',
            nodeId: '',
            nodeDeviceId: ''
        });
    }

    onNodeGwidChange(event) {
        this.setState({
            nodeGwid: event.target.value
        });
    }
    onNodeIdChange(event) {

        this.setState({
            nodeId: event.target.value
        });
    }
    onNodeDeviceIdChange(event) {

        this.setState({
            nodeDeviceId: event.target.value
        });
    }

    loadNodes(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    first: 100,
                    offset: 0,
                    name: `"${value}"`,
                    assigned: 'ASSIGNED'
                };

                garageService.getService(getPayucaConnectGateways(params))
                    .then((gateways) => {
                        resolve({
                            options: gateways.data.getPCGateways.gateways.map((gateway) => {
                                let label = gateway.name || gateway.gwid;
                                return {gwid: gateway.gwid, value: gateway.gwid, label: label}
                            })
                        })
                    })
                    .catch((err) => {
                        let errors = err.networkError.result.errors;
                        this.unsuccessful(errors.map((error) => error.message).join(" "));
                    });

            }, this.typingInterval)

        })
    }

    onNodeChanged(assignedNode) {
        this.setState({
            assignedNode: assignedNode
        });
    }

    createPCGateway = () => {
        let data = {
            device_id: this.state.nodeDeviceId,
            gwid: this.state.nodeGwid,
        };

        garageService.getService(createPCGateway(data))
            .then(() => {
                this.fetchGateways();
                this.setState({
                    assign: !this.state.assign,
                    assignedNode: '',
                    nodeGwid: '',
                    nodeDeviceId: '',
                }, () => {
                    this.success();
                })
            })
            .catch((err) => {
                console.log(err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    render() {
        const deviceIDField = this.state.selectedOption === 'Master' ? (
            <div>
                <Label htmlFor="newGatewayGwid">Neue Device ID</Label>
                <InputGroup>
                    <Input type="text" value={this.state.nodeDeviceId}
                           name="nodeDeviceId" id="nodeDeviceId" placeholder="1234567"
                           onChange={this.onNodeDeviceIdChange} required minLength={1}/>
                </InputGroup>
            </div>
        ) : null;

        const containerStyle = {
            zIndex: 1999
        };
        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" md="8" lg="8">
                        <Widget icon="icon-organization" color="info" header="PAYUCA Connect"/>
                    </Col>
                    <Col xs="12" sm="12" md="2" lg="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Knoten hinzufügen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleAssign}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="2" lg="2">
                        <DownloadPayucaConnectGateways totalSize={this.state.totalSize} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.gateways} version="4" striped remote
                                                fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true}
                                                options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="id" dataFormat={PayucaConnectGateways.ToGateway}>ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="gwid"
                                                       dataFormat={PayucaConnectGateways.ToGateway}
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getGWIDFilter
                                                       }}>GWID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="device_id"
                                                       dataFormat={PayucaConnectGateways.ToGateway} filter={{
                                        type: 'CustomFilter',
                                        getElement: this.getDeviceIDFilter
                                    }}>Device ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="name"
                                                       dataFormat={PayucaConnectGateways.ToGateway}>Name</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="master_id" dataFormat={PayucaConnectGateways.ToMasterGateway}>Master</TableHeaderColumn>
                                    <TableHeaderColumn width='150'
                                                       dataField="type"
                                                       filterFormatted
                                                       dataFormat={enumFormatter}
                                                       formatExtraData={gatewayType}
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getTypeFilter
                                                       }}>Typ</TableHeaderColumn>
                                    <TableHeaderColumn width='130'
                                                       dataField="health_status"
                                                       dataFormat={PayucaConnectGateways.HealthStyle}
                                                       formatExtraData={gatewayState}>Health</TableHeaderColumn>
                                    <TableHeaderColumn width='170'
                                                       dataField="payuca_connect_garage"
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getAssignedFilter
                                                       }}
                                                       dataFormat={PayucaConnectGateways.Assigned}>Assigned</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Create gateway */}
                <Modal isOpen={this.state.assign} toggle={this.toggleAssign}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleAssign}>Knoten hinzufügen</ModalHeader>
                    <ModalBody>
                        {this.state.assign &&
                            <div>
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle caret>
                                        {this.state.selectedOption}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => this.selectOption('Master')}>Master</DropdownItem>
                                        <DropdownItem onClick={() => this.selectOption('Node')}>Node</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <br/>
                                {deviceIDField}
                                <Label htmlFor="newGatewayGwid">Neue Gateway GWID</Label>
                                <InputGroup>
                                    <Input type="text" value={this.state.nodeGwid}
                                           name="nodeGwid" id="nodeGwid" placeholder="1234567"
                                           onChange={this.onNodeGwidChange} required minLength={1}/>
                                </InputGroup>
                                <br/>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.createPCGateway} disabled={this.state.selectedOption === 'Master' ? (!this.state.nodeDeviceId || !this.state.nodeGwid) : !this.state.nodeGwid}>
                            Hinzufügen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleAssign}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default PayucaConnectGateways;
