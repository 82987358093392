import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    Col, Input,
    InputGroup, InputGroupAddon, InputGroupText,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from 'reactstrap';
import {Link} from "react-router-dom";
import {BootstrapTable, ClearSearchButton, TableHeaderColumn} from "react-bootstrap-table";
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";

import gatewayService from "../../services/gatewayService";
import Widget from "../../components/Widget/Widget";
import getGatewaysQuery from "../../queries/getGateways_2";
import CustomSearchField from "../../components/CustomSearchField";
import CustomDropdownFilter from "../../components/CustomDropdownFilter";
import HealthStatus from "../../components/HealthStatus";
import dictionary from "../../utils/dictionary";
import AsyncSelect from "react-select/lib/Async";
import garageService from "../../services/garageService";
import getGateways_2 from "../../queries/getGateways_2";
import assignNode from "../../mutations/assignNode";
import DownloadGateways2 from "./DownloadGateways2/DownloadGateways2";


const gatewayType = {
    'CHECK_IN': 'Check In',
    'CHECK_OUT': 'Check Out',
    'CHECK_IN_OUT': 'Check In/Out',
    'MIDGATEWAY': 'Zwischentür',
    'MIDGATEWAY_ONLINE': 'Zwischentür (Online)',
    'MIDGATEWAY_OFFLINE': 'Zwischentür (Offline)',
    'NFC_WRITE': 'NFC Gateway',
    'EXIT_BARRIER': 'Ausfahrtsschranken',
    'EXIT_BARRIER_ONLINE': 'Ausfahrtsschranken (Online)',
    'EXIT_BARRIER_OFFLINE': 'Ausfahrtsschranken (Offline)'
};

const gatewayState = {
    'ONLINE': 'Online',
    'OFFLINE': 'Offline',
};

function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
}

class Gateways_2 extends Component {

    static ToGateway(cell, row) {
        const gwid = row.gwid;
        return <Link data-testid='gwid-row' to={`/gateways2/${gwid}`}> {cell} </Link>;
    }

    static Assigned(garage, cell) {
        if (cell.garage_gateway) {
            return <Link to={`/garages/${cell.garage_gateway.id}`}>{cell.garage_gateway.name}</Link>
        } else {
            return <div>-</div>
        }
    }

    static StateStyle(cell) {
        if (cell === 'ONLINE') {
            return <div><i className="fa fa-circle text-success Blink"/> {cell}</div>
        } else if (cell === 'OFFLINE') {
            return <div><i className="fa fa-circle text-danger Blink"/> {cell}</div>
        } else {
            return cell;
        }
    }

    static HealthStyle(cell) {
        if (cell) {
            return <HealthStatus healthStatus={cell}/>
        }
        return <p>{dictionary.NOT_AVAILABLE}</p>;
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            gateways: [],
            page: 1,
            totalSize: 0,

            nameFilter: "",
            gwidFilter: "",
            deviceIDFilter: "",
            stateFilter: "",
            typeFilter: "",
            assignedFilter: null,

            assign: false,
            nodeGwid: '',
            assignedNode: '',
        };

        this.unsuccessful = this.unsuccessful.bind(this);
        this.success = this.success.bind(this);

        this.onPageChange = this.onPageChange.bind(this);
        this.fetchGateways = this.fetchGateways.bind(this);
        this.getSearchField = this.getSearchField.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onGWIDFilter = this.onGWIDFilter.bind(this);
        this.onDeviceIDFilter = this.onDeviceIDFilter.bind(this);
        this.getClearButton = this.getClearButton.bind(this);
        this.onClearButtonClicked = this.onClearButtonClicked.bind(this);

        this.getStateFilter = this.getStateFilter.bind(this);
        this.handleStateFilter = this.handleStateFilter.bind(this);

        this.getTypeFilter = this.getTypeFilter.bind(this);
        this.handleTypeFilter = this.handleTypeFilter.bind(this);

        this.getAssignedFilter = this.getAssignedFilter.bind(this);
        this.handleAssignedFilter = this.handleAssignedFilter.bind(this);

        this.toggleAssign = this.toggleAssign.bind(this);
        this.onNodeGwidChange = this.onNodeGwidChange.bind(this);
        this.loadNodes = this.loadNodes.bind(this);
        this.onNodeChanged = this.onNodeChanged.bind(this);
        this.assignNode = this.assignNode.bind(this);


        this.typingTimer = null;
        this.typingInterval = 600;

    }

    unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    success() {
        return toast.success('Erfolgreich!');
    }

    getQueryParams() {
        return {
            first: 10,
            offset: (this.state.page - 1) * 10,
            name: `"${this.state.nameFilter}"`,
            gwid: `"${this.state.gwidFilter}"`,
            device_id: `"${this.state.deviceIDFilter}"`,
            state: this.state.stateFilter,
            type: this.state.typeFilter,
            assigned: this.state.assignedFilter
        }
    }

    componentDidMount() {
        this.fetchGateways();
        document.title = "PAYUCA Cockpit | Gateways 2.0"
    }


    fetchGateways() {
        gatewayService.getService(getGatewaysQuery(this.getQueryParams()))
            .then((gateways) => {
                this.setState({
                    gateways: gateways.data.getNewGateways.garageGateways,
                    loading: false,
                    totalSize: gateways.data.getNewGateways.count
                });
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    getSearchField(props) {
        return (
            <CustomSearchField
                defaultValue={this.state.nameFilter}
                placeholder={props.placeholder}
                search={this.onSearch}
            />
        )
    }

    onSearch(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.nameFilter !== val) {
            this.setState({nameFilter: val});
            this.filterApplied();
        }
    }

    onGWIDFilter(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.gwidFilter !== val) {
            this.setState({gwidFilter: val});
            this.filterApplied();
        }
    }

    onDeviceIDFilter(e) {
        let val = e.target.value.trim().replace(/\s\s+/g, ' ');
        if (this.state.deviceIDFilter !== val) {
            this.setState({deviceIDFilter: val});
            this.filterApplied();
        }
    }

    filterApplied() {
        this.setState({
            page: 1,
            loading: true,
            gateways: [],
        }, () => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
        })
    }

    getClearButton(onClick) {
        return (
            <ClearSearchButton
                onClick={(e) => {
                    this.onClearButtonClicked(onClick)
                }}
            />
        )
    }

    onClearButtonClicked(originalOnClick) {
        originalOnClick();
        if (this.state.nameFilter !== "") {
            this.setState({
                page: 1,
                loading: true,
                gateways: [],
                nameFilter: ""
            }, () => {
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout(this.fetchGateways, this.typingInterval)
            })
        }
    }

    getNoDataTableContent() {
        if (this.state.loading) {
            return (<div className="sk-rotating-plane"/>)
        }
        return "No gateways found"
    }

    onPageChange(page, sizePerPage) {
        this.setState({
            loading: true,
            gateways: [],
            page
        }, this.fetchGateways)
    }

    get tableOptions() {
        return {
            sortIndicator: true,
            hideSizePerPage: true,
            hidePageListOnlyOnePage: false,
            clearSearch: true,
            alwaysShowAllBtns: false,
            withFirstAndLast: false,

            searchField: this.getSearchField,
            noDataText: this.getNoDataTableContent(),
            onPageChange: this.onPageChange,
            clearSearchBtn: this.getClearButton,

            page: this.state.page,
            sizePerPage: 10
        }
    }

    handleStateFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            stateFilter: val
        }, this.fetchGateways)
    }


    getStateFilter() {
        return (
            <CustomDropdownFilter
                testId='state-filter'
                options={[{
                    value: "ONLINE", label: "ONLINE"
                }, {
                    value: "OFFLINE", label: "OFFLINE"
                }]}
                filterHandler={this.handleStateFilter}/>
        )
    }

    getHealthFilter() {
        return (
            <CustomDropdownFilter
                testId='state-filter'
                options={[{
                    value: "OK", label: dictionary.OK
                }, {
                    value: "WARNING", label: dictionary.WARNING
                }, {
                    value: "DANGER", label: dictionary.DANGER
                }]}/>
        )
    }

    handleTypeFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            typeFilter: val
        }, this.fetchGateways)
    }

    getTypeFilter() {
        let options = [
            {value: "CHECK_IN", label: "Check In"},
            {value: "CHECK_OUT", label: "Check Out"},
            {value: "CHECK_IN_OUT", label: "Check In/Out"},
            {value: "MIDGATEWAY", label: "Zwischentür"},
            {value: "MIDGATEWAY_ONLINE", label: "Zwischentür (Online)"},
            {value: "MIDGATEWAY_OFFLINE", label: "Zwischentür (Offline)"},
            {value: "NFC_WRITE", label: "NFC Gateway"},
            {value: "EXIT_BARRIER", label: "Ausfahrtsschranken"},
            {value: "EXIT_BARRIER_ONLINE", label: "Ausfahrtsschranken (Online)"},
            {value: "EXIT_BARRIER_OFFLINE", label: "Ausfahrtsschranken (Offline)"},
        ];
        return (
            <CustomDropdownFilter
                testId='type-filter'
                defaultValue={''}
                options={options}
                filterHandler={this.handleTypeFilter}/>
        )
    }

    getGWIDFilter = () => {
        return (
            <CustomSearchField
                placeholder="Search by GWID"
                search={this.onGWIDFilter}/>
        )
    };

    getDeviceIDFilter = () => {
        return (
            <CustomSearchField
                placeholder="Search by ID"
                search={this.onDeviceIDFilter}/>
        )
    };

    handleAssignedFilter(val) {
        this.setState({
            loading: true,
            gateways: [],
            page: 1,
            assignedFilter: val === "true" ? "ASSIGNED" : (val === "false" ? "UNASSIGNED" : null)
        }, this.fetchGateways)
    }

    getAssignedFilter() {
        let options = [
            {value: true, label: "Ja"},
            {value: false, label: "Nein"}
        ];
        return (
            <CustomDropdownFilter
                options={options}
                filterHandler={this.handleAssignedFilter}/>
        )
    }

    toggleAssign() {
        this.setState({
            assign: !this.state.assign,
            assignedNode: '',
            nodeGwid: ''
        });
    }

    onNodeGwidChange(event) {

        this.setState({
            nodeGwid: event.target.value
        });
    }

    loadNodes(value) {
        return new Promise(resolve => {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {

                let params = {
                    first: 100,
                    offset: 0,
                    name: `"${value}"`,
                    assigned: 'ASSIGNED'
                };

                garageService.getService(getGateways_2(params))
                    .then((gateways) => {
                        resolve({
                            options: gateways.data.getNewGateways.garageGateways.map((gateway) => {
                                let label = gateway.name || gateway.gwid;

                                if (gateway.garage_gateway) {
                                    if(gateway.name){
                                        label = gateway.name + ' | ' + gateway.garage_gateway.name
                                    } else {
                                        label = gateway.gwid + ' | ' + gateway.garage_gateway.name;

                                    }
                                }
                                return {gwid: gateway.gwid, value: gateway.gwid, label: label}
                            })
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }, this.typingInterval)

        })
    }

    onNodeChanged(assignedNode) {
        this.setState({
            assignedNode: assignedNode
        });
    }

    assignNode() {
        let data = {
            gwid: `PAY_${this.state.nodeGwid}`,
            masterGwid: this.state.assignedNode.gwid,
        };

        garageService.createService(assignNode(data))
            .then(() => {
                this.fetchGateways();
                this.setState({
                    assign: !this.state.assign,
                    assignedNode: '',
                    nodeGwid: ''
                }, () => {
                    this.success();
                })
            })
            .catch((err) => {
                console.log(err);
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }


    render() {
        const containerStyle = {
            zIndex: 1999
        };
        return (
            <div className="animated">
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
                <Row>
                    <Col xs="12" sm="12" md="8" lg="8">
                        <Widget icon="icon-organization" color="info" header="PAYUCA Gateways 2.0"/>
                    </Col>
                    <Col xs="12" sm="12" md="2" lg="2">
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Knoten hinzufügen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={this.toggleAssign}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="2" lg="2">
                        <DownloadGateways2 totalSize={this.state.totalSize} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Card>
                            <CardBody>
                                <BootstrapTable data={this.state.gateways} version="4" striped remote
                                                fetchInfo={{dataTotalSize: this.state.totalSize}} pagination={true}
                                                options={this.tableOptions} search>
                                    <TableHeaderColumn width='150' isKey={true} dataField="gwid"
                                                       dataFormat={Gateways_2.ToGateway}
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getGWIDFilter
                                                       }}>GWID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="device_id"
                                                       dataFormat={Gateways_2.ToGateway} filter={{
                                        type: 'CustomFilter',
                                        getElement: this.getDeviceIDFilter
                                    }}>Device ID</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="name"
                                                       dataFormat={Gateways_2.ToGateway}>Name</TableHeaderColumn>
                                    <TableHeaderColumn width='150' dataField="is_master">Is Master</TableHeaderColumn>
                                    <TableHeaderColumn width='150'
                                                       dataField="type"
                                                       filterFormatted
                                                       dataFormat={enumFormatter}
                                                       formatExtraData={gatewayType}
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getTypeFilter
                                                       }}>Typ</TableHeaderColumn>
                                    <TableHeaderColumn width='130'
                                                       dataField="state"
                                                       dataFormat={Gateways_2.StateStyle}
                                                       formatExtraData={gatewayState}
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getStateFilter
                                                       }}>State</TableHeaderColumn>
                                    <TableHeaderColumn width='130'
                                                       dataField="health_status"
                                                       dataFormat={Gateways_2.HealthStyle}
                                    >Health</TableHeaderColumn>
                                    <TableHeaderColumn width='170'
                                                       dataField="gatewayGarage"
                                                       filter={{
                                                           type: 'CustomFilter',
                                                           getElement: this.getAssignedFilter
                                                       }}
                                                       dataFormat={Gateways_2.Assigned}>Assigned</TableHeaderColumn>
                                </BootstrapTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Convert gateway */}
                <Modal isOpen={this.state.assign} toggle={this.toggleAssign}
                       className={'modal-success ' + this.props.className}>
                    <ModalHeader toggle={this.toggleAssign}>Knoten hinzufügen</ModalHeader>
                    <ModalBody>
                        <Label htmlFor="newGatewayGwid">Neue Gateway GWID</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>PAY_</InputGroupText>
                            </InputGroupAddon>
                            <Input type="number" value={this.state.nodeGwid}
                                   name="nodeGwid" id="nodeGwid" placeholder="1234567"
                                   onChange={this.onNodeGwidChange}/>
                        </InputGroup>
                        <br/>
                        <Label htmlFor="newGatewayGwid">Master Gateway</Label>
                        <AsyncSelect
                            name="assignedGarage"
                            loadOptions={this.loadNodes}
                            value={this.state.assignedNode}
                            onChange={this.onNodeChanged}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.assignNode}>
                            Zuordnen</Button>{' '}
                        <Button color="secondary" onClick={this.toggleAssign}>Abbrechen</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default Gateways_2;
