import React, {useState, useEffect} from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    Row,
} from 'reactstrap';
import AsyncSelect from 'react-select/lib/Async';
import 'react-select/dist/react-select.min.css';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import 'spinkit/css/spinkit.css';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Widget from "../../components/Widget/Widget";
import gatewayService from "../../services/gatewayService";
import garageService from "../../services/garageService";
import getGaragesQuery from '../../queries/getGarages';
import datetimeFormatter from "../../utils/datetimeFormatter";
import Logs from "../../components/Logs";
import {history} from "../../App";
import assignNode from "../../mutations/assignPayucaConnectNode";
import assignMaster from "../../mutations/assignPayucaConnectMaster";
import unassignMaster from "../../mutations/unassignPayucaConnectMaster";
import unassignNode from "../../mutations/unassignPayucaConnectNode";
import dictionary from "../../utils/dictionary";
import Nodes from "../../components/Nodes";
import {Link} from "react-router-dom";
import updatePCGateway from "../../mutations/updatePayucaConnectGateway";
import deletePCGateway from "../../mutations/deletePayucaConnectGateway";
import getPayucaConnectGatewayById from "../../queries/getPayucaConnectGatewayById";
import getPayucaConnectGateways from "../../queries/getPayucaConnectGateways";
import Select from "react-select";
import getTenantsQuery from "../../queries/getTenantsQuery";
import rebootNode from "../../mutations/rebootNode";
import triggerPCHealthCheck from "../../mutations/triggerPCHealthCheck";


const PayucaConnectGateway = (props) => {
    const [loading, setLoading] = useState(true);
    const [garageDataLoading, setGarageDataLoading] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [isUnassignNode, setUnassignNode] = useState(false);
    const [isUnassignMaster, setUnassignMaster] = useState(false);
    const [isAssignNode, setAssignNode] = useState(false);
    const [isAssignMaster, setAssignMaster] = useState(false);
    const [assignedValue, setAssignedValue] = useState('');
    const [tenants, setTenants] = useState([])
    const [productTypes, setProductType] = useState([{value: "B2C", label: "B2C"}, {value: "B2B", label: "B2B"}, {value: "B2B_B2C", label: "Hybrid"},])
    const [gateway, setGateway] = useState(null);
    const [isDeleteGateway, setDeleteGateway] = useState(false);
    let typingTimer;
    const typingInterval = 600;

    useEffect(() => {
        fetchGateway();
        getTenants();
    }, [props]);

    function fetchGateway() {
        const {match: {params}} = props;
        gatewayService.getService(getPayucaConnectGatewayById(params))
            .then((gateway) => {
                setGateway(gateway.data.getPCGatewayById.gateway);
                setLoading(false);
                const gatewayName = gateway.data.getPCGatewayById.gateway.name;
                const gatewayGwid = gateway.data.getPCGatewayById.gateway.gwid;
                return document.title = `PC Gateway | ${gatewayName || gatewayGwid || dictionary.NOT_AVAILABLE}`;
            })
            .catch((err) => {
                if (err.networkError && err.networkError.statusCode === 404) {
                    history.push('/404')
                }
            });
    }

    function handleProductType(product_type) {
        setGateway({
            ...gateway,
            product_type: !product_type ? null : product_type.value
        })
    }

    function handleTenant(tenant) {
        setGateway({
            ...gateway,
            tenant_id: !tenant ? null : tenant.value,
        });
    }

    function getTenants() {
        gatewayService.getService(getTenantsQuery())
            .then((res) => {
                let tenants = res.data.getTenants.tenants;
                tenants = tenants.map((item) => {
                    return {
                        value: item.Id,
                        label: item.Name
                    }
                })
                setTenants(tenants)
            })
            .catch((err) => {
                console.log(err)
                let errors = err.networkError.result.errors;
                this.unsuccessful(errors.map((error) => error.message).join(" "));
            })
    }

    function handleChange(event) {
        const target = event.target;
        let value = target.value;
        const name = target.name;
        let _gateway = {...gateway};

        if (name === "type" && !value.length) {
            value = null;
        } else if (name === "lat" || name === "long") {
            _gateway.position = {
                ..._gateway.position,
                [name]: value,
            };
        }

        if ((name === "relay0" || name === "relay1" || name === "relay2") && gateway.garage_id) {
            switch (name) {
                case "relay0":
                    _gateway.relay_timings[0] = value;
                    break;
                case "relay1":
                    _gateway.relay_timings[1] = value;
                    break;
                case "relay2":
                    _gateway.relay_timings[2] = value;
                    break;
                default:
                    break;
            }
        }

        _gateway[name] = value;

        setGateway(_gateway);
    }

    function unsuccessful(msg = 'Erfolglos!') {
        return toast.error(msg);
    }

    function success() {
        return toast.success('Erfolgreich!');
    }

    function handleAssignErrors(err) {
        if (err && err.networkError) {
            const errors = err.networkError.result.errors;
            unsuccessful(errors.map((error) => error.message).join(" "));
        }
    }

    function onGarageChanged(assignedGarage) {
        setAssignedValue(assignedGarage);
    }

    function loadMasterGateways(value) {
        return new Promise(resolve => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                let params = {
                    first: 100,
                    offset: 0,
                    name: `"${value}"`,
                    is_master: true
                };

                garageService.getService(getPayucaConnectGateways(params))
                    .then((gateways) => {
                        resolve({
                            options: gateways.data.getPCGateways.gateways.map((gateway) => {
                                let label = gateway.name || gateway.gwid;

                                if(gateway.payuca_connect_garage){
                                    label += ` | ${gateway.payuca_connect_garage.name}`;
                                }

                                return { gwid: gateway.gwid, master_id: gateway.id, label: label }
                            })
                        })
                    });
            })
        })
    }

    function loadGarages(value) {
        setGarageDataLoading(true);
        return new Promise(resolve => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {

                let gwParams = {
                    first: 100,
                    offset: 0,
                    name: `"${value}"`
                };

                let garageParams = {
                    offset: 0,
                    limit: 10,
                    searchTerm: value
                };

                let pcGatewaysPromise = garageService
                    .getService(getPayucaConnectGateways(gwParams))
                    .then((gateways) => {
                        return gateways.data.getPCGateways.gateways.map((gateway) => gateway.garage_id)
                    })
                    .catch((err) => {
                        let errors = err.networkError.result.errors;
                        this.unsuccessful(errors.map((error) => error.message).join(" "));
                    });

                let garagesPromise = garageService
                    .getService(getGaragesQuery(garageParams))
                    .then((garages) => {
                        return garages.data.getGarages.garages
                            .filter((garage) => garage.gateway_type === "PC")
                            .map((garage) => ({value: garage.id, label: garage.name}))
                    })
                    .catch((err) => {
                        let errors = err.networkError.result.errors;
                        this.unsuccessful(errors.map((error) => error.message).join(" "));
                    });

                Promise.all([pcGatewaysPromise, garagesPromise])
                    .then(([pcGateways, garages]) => {
                        setGarageDataLoading(false);
                        resolve({ options: garages.filter(garage => !pcGateways.includes(garage.value))});
                    })
                    .catch((err) => {
                        let errors = err.networkError.result.errors;
                        this.unsuccessful(errors.map((error) => error.message).join(" "));
                        setGarageDataLoading(false);
                    });
            }, typingInterval)

        })
    }

    function toggleUnassignNode() {
        setUnassignNode(!isUnassignNode);
    }

    function toggleUnassignMaster() {
        setUnassignMaster(!isUnassignMaster);
    }

    function toggleAssignNode() {
        setAssignNode(!isAssignNode);
    }

    function toggleAssignMaster() {
        setAssignMaster(!isAssignMaster);
    }
    function toggleDeleteGateway() {
        setDeleteGateway(!isDeleteGateway);
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function startUpdateGateway() {
        garageService.createService(updatePCGateway(gateway))
            .then((response) => {
                setGateway(response.data.updatePCGateway.gateway);
                success();
                toggleDisabled();
                document.title = "PC Gateway | " + gateway.id || dictionary.NOT_AVAILABLE;
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function startAssignMaster() {
        const data = {
            id: gateway.id,
            garageId: assignedValue.value
        };

        garageService.createService(assignMaster(data))
            .then((response) => {
                fetchGateway();
                setAssignMaster(false);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            });
    }

    function startUnassignMaster(){
        const data = {
            id: gateway.id
        };

        garageService.createService(unassignMaster(data))
            .then((response)=>{
                fetchGateway();
                setUnassignMaster(false);
                success();
            })
            .catch((err)=>{
                handleAssignErrors(err);
            })
    }

    function startDeleteGateway(){
        const data = {
            id: gateway.id
        };

        garageService.createService(deletePCGateway(data))
            .then((response)=>{

                history.push('/payuca-connect');

                success();
            })
            .catch((err)=>{
                handleAssignErrors(err);
            })
    }

    function startAssignNode() {
        const data = {
            id: gateway.id,
            master_id: assignedValue.master_id
        };

        garageService.createService(assignNode(data))
            .then((response) => {
                fetchGateway();
                setAssignNode(!isAssignNode);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            })
    }

    function startUnassignNode() {
        const data = {
            id: gateway.id
        };

        garageService.createService(unassignNode(data))
            .then((response) => {
                fetchGateway();
                setUnassignNode(!isUnassignNode);
                success();
            })
            .catch((err) => {
                handleAssignErrors(err);
            });
    }

    function handleRebootModule(moduleType) {
        garageService.createService(rebootNode({id: gateway.id, component: moduleType}))
            .then(() => {
                success();
            })
            .catch((err)=>{
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function onTriggerHealthCheck() {
        garageService.createService(triggerPCHealthCheck(gateway.id))
            .then((response) => {
                success();
            })
            .catch((err) => {
                let errors = err.networkError.result.errors;
                unsuccessful(errors.map((error) => error.message).join(" "));
            });
    }

    function renderNodeGateway() {
        return <Widget
            icon="icon-plus"
            color="success"
            header="Knoten zuweisen"
            invert
            style={{cursor: "pointer"}}
            onClick={toggleAssignNode}
        />
    }

    function renderInitialHeader() {
        return (
            <Row className="justify-content-between">
                <Col>
                    <Widget icon="icon-organization" color="info"
                            header={gateway.name || gateway.gwid}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-plus"
                        color="danger"
                        header="Gateway löschen"
                        invert
                        style={{cursor: "pointer"}}
                        onClick={toggleDeleteGateway}
                    />
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    {!gateway.device_id ? renderNodeGateway() :
                        <Widget
                            icon="icon-plus"
                            color="success"
                            header="Knoten zuweisen"
                            invert
                            style={{cursor: "pointer"}}
                            onClick={toggleAssignMaster}
                        />
                    }
                </Col>
            </Row>
        )
    }

    function renderAssignedHeader() {
        return (
            <Row>
                <Col xs="12" sm="12" md="10" lg="10">
                    <Widget icon="icon-organization" color="info"
                            header={gateway.name || gateway.gwid}/>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2">
                    <Widget
                        icon="icon-minus"
                        color="warning"
                        header={!gateway.master_id ? 'Master Aufheben' : 'Node Aufheben'}
                        invert
                        style={{cursor: "pointer"}}
                        onClick={!gateway.master_id ? toggleUnassignMaster : toggleUnassignNode}
                    />
                </Col>
            </Row>
        )
    }

    function renderHeader() {
        if (gateway.garage_id || gateway.master_id) {
            return renderAssignedHeader()
        } else {
            return renderInitialHeader()
        }
    }

    function renderState(cell) {
        const color = cell ? 'text-success' : 'text-danger';
        return <div><i className={`fa fa-circle ${color} Blink`}/> {cell ? 'Online' : 'Offline'}</div>
    }

    function renderModule(moduleName, onReboot, state, moduleType) {
        return (
            <Card>
                <CardHeader>
                    <strong>{moduleName}</strong>
                </CardHeader>
                <CardBody>
                    {renderState(state)}
                    <Button type="submit" size="sm" color="success" className={"mr-1"}
                            onClick={() => onReboot(moduleType)}>
                        <i className="fa fa-refresh"/>
                        Reboot
                    </Button>
                </CardBody>
            </Card>
        )
    }

    function renderActiveRelayState(relayTimings) {
        return relayTimings.map((relay, index) => {
            return (
                <FormGroup row key={index}>
                    <Col xs="12" md="12">
                        <Input type="select" name={"relay" + index} id={`relay`}
                               value={gateway.relay_timings[index]}
                               disabled
                               onChange={handleChange}>
                            <option value={5}>True (5)</option>
                            <option value={0}>False (0)</option>
                        </Input>
                    </Col>
                </FormGroup>
            )
        }).reverse();
    }


    const containerStyle = {
        zIndex: 1999
    };

    if (loading) {
        return (
            <div>
                <div className="sk-rotating-plane"/>
                <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
            </div>
        );
    }

    return (
        <div className="animated fadeIn">
            <ToastContainer position="bottom-right" autoClose={5000} style={containerStyle}/>
            {renderHeader()}
            <Row>
                <Col md={3}>
                    {renderModule('BLE Modul', handleRebootModule, gateway.health_status.ble_state, 'BLE')}
                </Col>
                <Col md={3}>
                    {renderModule('NFC Modul', handleRebootModule, gateway.health_status.nfc_state, 'NFC')}
                </Col>
                <Col md={3}>
                    {renderModule('RF Modul', handleRebootModule, gateway.health_status.rf_state, 'RF')}
                </Col>
                <Col md={3}>
                    {renderModule('Main Controller', handleRebootModule, gateway.health_status.main_controller_state, 'DEVICE')}
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="12" md="12">
                    <Card>
                        <CardHeader>
                            PC Gateway Infos
                            <div className="card-header-actions">
                                {
                                    !disabled
                                        ?
                                        <div>
                                            <Button type="submit" size="sm" color="success" className={"mr-1"}
                                                    onClick={startUpdateGateway}>
                                                <i className="fa fa-dot-circle-o"></i> Speichern
                                            </Button>
                                            <Button type="reset" size="sm" color="danger" onClick={toggleDisabled}>
                                                <i className="fa fa-ban"></i> Abbrechen</Button></div> :
                                        <a data-testid="gateway-edit-button"
                                           className="card-header-action btn btn-setting" onClick={toggleDisabled}><i
                                            className="icon-pencil"></i></a>
                                }
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="12" sm="12" md="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Name</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input data-testid="gateway-name-input" type="text" name="name" id="name"
                                                   value={!gateway.name ? "" : gateway.name}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Device ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="device_id" id="device_id"
                                                   value={gateway.device_id || ""} onChange={handleChange}
                                                   disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Typ</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="select" name="type" id="type"
                                                   value={!gateway.type ? dictionary.NOT_AVAILABLE : gateway.type}
                                                   onChange={handleChange} disabled={disabled}>
                                                <option value="">-</option>
                                                <option value="CHECK_IN">Check In</option>
                                                <option value="CHECK_OUT">Check Out</option>
                                                <option value="CHECK_IN_OUT">Check In/Out</option>
                                                <option value="MIDGATEWAY">Zwischentür</option>
                                                <option value="REPEATER">Signalverstärker</option>
                                                <option value="EXIT_BARRIER">Ausfahrtsschranken</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Garage ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {gateway.garage_id && <Link to={`/garages/${gateway.garage_id}`}>{`${gateway.payuca_connect_garage.name} | ${gateway.garage_id}`}</Link>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Master ID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {gateway.master_id && <Link to={`/payuca-connect/${gateway.master_id}`}>{`${gateway.master_id}`}</Link>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Health</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {renderState(gateway.health_status.connection)}
                                            <Button type="submit" size="sm" color="success" className={"mr-1"}
                                                    onClick={onTriggerHealthCheck}>
                                                <i className="fa fa-refresh"/>
                                                Trigger health check
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="latitude">Breitengrad</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" name="lat" id="lat" parent="position"
                                                   value={!gateway.position ? "" : gateway.position.lat}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="longitude">Längengrad</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="number" id="long" name="long" parent="position"
                                                   value={!gateway.position ? "" : gateway.position.long}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Tenant</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Select
                                                value={!gateway ? null : gateway.tenant_id}
                                                options={tenants}
                                                onChange={handleTenant}
                                                disabled={disabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="12" md="6">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Active Relays</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            {gateway.garage_id ? renderActiveRelayState(gateway.relay_timings) : dictionary.NOT_AVAILABLE}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="gwid">GWID</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input data-testid="gateway-gwid-input" type="text" name="gwid" id="gwid"
                                                   value={gateway.gwid}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Erstellt am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="created_at" id="created_at"
                                                   value={datetimeFormatter.forDisplay(gateway.created_at)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Aktualisiert am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="updated_at" id="updated_at"
                                                   value={datetimeFormatter.forDisplay(gateway.updated_at)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Gelöscht am</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="deleted_at" id="deleted_at"
                                                   value={!gateway.deleted_at ? "-" : datetimeFormatter.forDisplay(gateway.deleted_at)}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">Product type</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Select
                                                value={!gateway.product_type ? null : gateway.product_type}
                                                options={productTypes}
                                                onChange={handleProductType}
                                                disabled={disabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">STM Firmware</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="stm_fw" id="stm_fw"
                                                   value={!gateway.stm_fw ? "" : gateway.stm_fw}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">RF Firmware</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="rf_fw" id="rf_fw"
                                                   value={!gateway.rf_fw ? "" : gateway.rf_fw}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label htmlFor="selectSm">ESP Firmware</Label>
                                        </Col>
                                        <Col xs="12" md="9">
                                            <Input type="text" name="esp_fw" id="esp_fw"
                                                   value={!gateway.esp_fw ? "" : gateway.esp_fw}
                                                   onChange={handleChange} disabled/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <hr/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12">
                                    <FormGroup row>
                                        <Col md="3">
                                            <Label>Notiz</Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs="12" md="12">
                                            <Input type="textarea" name="note" id="note"
                                                   value={!gateway.note ? "" : gateway.note}
                                                   onChange={handleChange} disabled={disabled}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {!gateway.master_id && gateway.device_id &&
                <Row>
                    <Col xs="12" sm="12" lg="12">
                        <Nodes nodes={gateway.nodes}/>
                    </Col>
                </Row>
            }
            <Row>
                <Col xs="12" sm="12" lg="12">
                    {
                        garageDataLoading ?
                            <div className="sk-rotating-plane"/>
                            :
                            <Logs
                                object_id={gateway.gwid}
                                object_content_type="PC_GATEWAY"
                            />
                    }
                </Col>
            </Row>

            {/* Modals */}

            {/* Unassign node */}
            <Modal isOpen={isUnassignNode} toggle={toggleUnassignNode}
                   className={'modal-warning ' + props.className}>
                <ModalHeader toggle={toggleUnassignNode}>Heben Sie die Zuweisung des Knotens auf</ModalHeader>
                <ModalFooter>
                    <Button color="warning" onClick={startUnassignNode}>Zuweisung des Knotens aufheben</Button>{' '}
                    <Button color="secondary" onClick={toggleUnassignNode}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* Assign node to master */}
            <Modal isOpen={isAssignNode} toggle={toggleAssignNode}
                   className={'modal-success ' + props.className}>
                <ModalHeader toggle={toggleAssignNode}>Knoten zuweisen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        name="assignedGarage"
                        loadOptions={loadMasterGateways}
                        value={assignedValue}
                        onChange={onGarageChanged}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={startAssignNode}>Knoten zuweisen</Button>{' '}
                    <Button color="secondary" onClick={toggleAssignNode}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/*Assign master to garage*/}
            <Modal isOpen={isAssignMaster} toggle={toggleAssignMaster}
                   className={'modal-success ' + props.className}>
                <ModalHeader toggle={toggleAssignMaster}>Master zuweisen</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        name="assignedGarage"
                        loadOptions={loadGarages}
                        value={assignedValue}
                        onChange={onGarageChanged}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={startAssignMaster}>Master zuweisen</Button>{' '}
                    <Button color="secondary" onClick={toggleAssignMaster}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/*Delete Gateway*/}
            <Modal isOpen={isDeleteGateway} toggle={toggleDeleteGateway}
                   className={'modal-danger ' + props.className}>
                <ModalHeader toggle={toggleDeleteGateway}>Gateway löschen</ModalHeader>
                <ModalBody>
                    <p>Sind Sie sicher, dass Sie dieses Gateway löschen möchten?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={startDeleteGateway}>Gateway löschen</Button>{' '}
                    <Button color="secondary" onClick={toggleDeleteGateway}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            {/* Unassign master */}
            <Modal isOpen={isUnassignMaster} toggle={toggleUnassignMaster}
                   className={'modal-warning ' + props.className}>
                <ModalHeader toggle={toggleUnassignMaster}>Heben Sie die Zuordnung des Masters auf</ModalHeader>
                <ModalFooter>
                    <Button color="warning" onClick={startUnassignMaster}>Zuordnung des Masters aufheben</Button>{' '}
                    <Button color="secondary" onClick={toggleUnassignMaster}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
};

export default PayucaConnectGateway;
